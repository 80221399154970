import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems, MenuAdminItems } from '../../../shared/menu-items/menu-items';

import { environment } from '../../../../environments/environment';
// Services
import { RequestAPIService } from '../../../services/request-api.service'
import { GlobalHandlerService } from '../../../services/global-handler.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  nameAdmin = ''
  roleCode = ''
  roleName = ''

  private _mobileQueryListener: () => void;
  status: boolean = true;

  itemSelect: number[] = []

  filterMenuItems: any;

  subclickEvent() {
    this.status = true;
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public menuAdminItems: MenuAdminItems,
    private router: Router,
    private requestAPIService: RequestAPIService,
    private globalHandlerService: GlobalHandlerService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    if (!this.requestAPIService.isLoggedIn() || this.requestAPIService.getTokenAdmin() == '') {
      this.router.navigate(['/']);
    }
    this.nameAdmin = this.requestAPIService.getNameAdmin()
    this.roleCode = this.requestAPIService.getRoleCodeAdmin()
    this.roleName = this.requestAPIService.getRoleNameAdmin()
    this.filterMenuItems = this.menuItems.getMenuitem();
    ``
    console.log(' this.filterMenuItems ', this.filterMenuItems);
    console.log(this.roleName)
    if (this.roleName === 'MKT' || this.roleName === 'MKT_SP' || this.roleName === 'MKT_WS') {
      let arr_show = ['Direct Assigned Sale', 'Project Wait Management']
      if (this.roleName === 'MKT_SP') {
        arr_show = ['Direct Assigned Sale']
      } else if (this.roleName === 'MKT_WS') {
        arr_show = ['Project Wait Management']
      }
      this.filterMenuItems = this.filterMenuItems.filter(x => arr_show.includes(x.name.toString()));
    } else if (this.roleCode !== 'SM' && this.roleCode !== 'ZM' && this.roleCode !== 'GZM') {
      // this.filterMenuItems = this.filterMenuItems.filter(x => x.name.toString() !== 'Data Management');
      let arr_no_show = ['Data Management', 'Direct Assigned Sale', 'Project Wait Management']
      this.filterMenuItems = this.filterMenuItems.filter(x => !arr_no_show.includes(x.name.toString()));
    } else {
      let arr_no_show = ['Direct Assigned Sale', 'Project Wait Management']
      this.filterMenuItems = this.filterMenuItems.filter(x => !arr_no_show.includes(x.name.toString()));
    }

    if (this.roleCode !== 'ZM' && this.roleCode !== 'GZM' && this.roleCode !== 'NATION_WIDE' && this.roleCode !== 'AD') {
      let arr_no_show = ['Salesman Report', "Rating Report"];

      // Remove submenu items that should be hidden
      const dataRe = this.filterMenuItems.map(x => {
        if (x.name === 'Sale Report') {
          return {
            ...x,
            children: x.children
              ? x.children.filter(subItem =>
                !arr_no_show.includes(subItem.name.toString())
              )
              : []
          };
        }
        return x;
      });

      console.log("reData ", dataRe);
      this.filterMenuItems = dataRe;
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onLogOut() {
    this.requestAPIService.logout();
    this.router.navigate(['/']);
  }

}
