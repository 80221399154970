import { Injectable } from "@angular/core";

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUADMINITEMS = [
  {
    state: "reports",
    subState: "overall",
    name: "Summary Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'overall', name: 'Summary', type: 'link' },
      { state: "prospect", name: "Prospect", type: "link" },
      { state: "booking", name: "Booking", type: "link" },
      { state: "delivery", name: "Delivery", type: "link" },
    ],
  },
  {
    state: "reports",
    subState: "saleReport",
    name: "Sale Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'saleReport', name: 'Sale Report', type: 'link' }
      { state: "prospect2", name: "Prospect – Summary", type: "link" },
      { state: "prospect3", name: "Prospect – Daily Data", type: "link" },
      { state: "booking2", name: "Booking – Summary", type: "link" },
      { state: "booking3", name: "Booking – Daily Data", type: "link" },
      { state: "reportSalesman", name: "Salesman Report", type: "link" },
      { state: "reportRating", name: "Rating Report", type: "link" },
    ],
  },
  {
    state: "reports",
    subState: "marketingReport",
    name: "Marketing Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'saleReport', name: 'Sale Report', type: 'link' }
      { state: "crmoverall", name: "CRM Overall", type: "link" },
      {
        state: "dealerfollowuplead",
        name: "Dealer Follow up Lead",
        type: "link",
      },
      { state: "dealeractivity", name: "Dealer Activity", type: "link" },
      { state: "extractprospect", name: "Raw Data Report", type: "link" },
    ],
  },
  // {
  //   state: 'reports',
  //   subState: 'dataManagement',
  //   name: 'Data Management',
  //   type: 'sub',
  //   icon: 'insert_chart',
  //   children: [
  //     { state: 'bookingManagement', name: 'Booking Management', type: 'link' },
  //     { state: 'prospectManagement', name: 'Prospect Management', type: 'link' },
  //     { state: 'leadAssignment', name: 'Lead Assignment', type: 'link' }
  //   ]
  // },

  {
    state: "reports",
    subState: "userLogReport",
    name: "User Log Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'userLogReport', name: 'User Log Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "testDriveRatio",
    name: "Test Drive Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'testDriveRatio', name: 'Test Drive Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "closingRatio",
    name: "Closing Ratio Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'closingRatio', name: 'Closing Ratio Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "convertionRatio",
    name: "Conversion Ratio Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'convertionRatio', name: 'Conversion Ratio Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "scProductivity",
    name: "SC Productivity Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'scProductivity', name: 'SC Product Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "highAttention",
    name: "High Attention Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      { state: "warningReport", name: "Warning Attention", type: "link" },
      { state: "exportReport", name: "Export Attention", type: "link" },
      { state: "importReport", name: "Import Attention", type: "link" },
    ],
  },
  {
    state: "admin",
    subState: "change-password-dealer",
    name: "Update Password Dealer",
    type: "sub",
    icon: "settings",
    children: [
      // { state: 'scProductivity', name: 'SC Product Report', type: 'link' }
    ],
  },
  {
    state: "admin",
    subState: "compare",
    name: "Comparison Cars",
    type: "sub",
    icon: "list",
    children: [
      { state: "compare-export", name: "Export Comparison Cars", type: "link" },
      { state: "compare-import", name: "Import Comparison Cars", type: "link" },
      { state: "upload-compare-image", name: "Upload Car Image", type: "link" },
    ],
  },
  {
    state: "admin",
    subState: "brochures",
    name: "Brochures",
    type: "sub",
    icon: "list",
    children: [
      { state: "brochures-create", name: "Create Brochures", type: "link" },
    ],
  },
  {
    state: "admin",
    subState: "news",
    name: "News",
    type: "sub",
    icon: "list",
    children: [{ state: "news-create", name: "Create News", type: "link" }],
  },
];

const MENUITEMS = [
  {
    state: "reports",
    subState: "overall",
    name: "Summary Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'overall', name: 'Summary', type: 'link' },
      { state: "prospect", name: "Prospect", type: "link" },
      { state: "booking", name: "Booking", type: "link" },
      { state: "delivery", name: "Delivery", type: "link" },
    ],
  },
  {
    state: "reports",
    subState: "saleReport",
    name: "Sale Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'saleReport', name: 'Sale Report', type: 'link' }
      { state: "prospect2", name: "Prospect – Summary", type: "link" },
      { state: "prospect3", name: "Prospect – Daily Data", type: "link" },
      { state: "booking2", name: "Booking – Summary", type: "link" },
      { state: "booking3", name: "Booking – Daily Data", type: "link" },
      { state: "reportSalesman", name: "Salesman Report", type: "link" },
      { state: "reportRating", name: "Rating Report", type: "link" },
    ],
  },
  {
    state: "reports",
    subState: "marketingReport",
    name: "Marketing Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      { state: "crmoverall", name: "CRM Overall", type: "link" },
      {
        state: "dealerfollowuplead",
        name: "Dealer Follow up Lead",
        type: "link",
      },
      { state: "dealeractivity", name: "Dealer Activity", type: "link" },
      { state: "extractprospect", name: "Raw Data Report", type: "link" },
    ],
  },
  {
    state: "reports",
    subState: "dataManagement",
    name: "Data Management",
    type: "sub",
    icon: "insert_chart",
    children: [
      {
        state: "prospectManagement",
        name: "Prospect Management",
        type: "link",
      },
      { state: "bookingManagement", name: "Booking Management", type: "link" },
      { state: "leadAssignment", name: "Lead Assignment", type: "link" },
      { state: "updateVin", name: "Update Vin", type: "link" },
    ],
  },
  {
    state: "reports",
    subState: "directAssignedSale",
    name: "Direct Assigned Sale",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'closingRatio', name: 'Closing Ratio Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "projectWaitManagement",
    name: "Project Wait Management",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'closingRatio', name: 'Closing Ratio Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "userLogReport",
    name: "User Log Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'userLogReport', name: 'User Log Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "testDriveRatio",
    name: "Test Drive Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'testDriveRatio', name: 'Test Drive Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "closingRatio",
    name: "Closing Ratio Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'closingRatio', name: 'Closing Ratio Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "convertionRatio",
    name: "Conversion Ratio Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'convertionRatio', name: 'Conversion Ratio Report', type: 'link' }
    ],
  },
  {
    state: "reports",
    subState: "scProductivity",
    name: "SC Productivity Report",
    type: "sub",
    icon: "insert_chart",
    children: [
      // { state: 'scProductivity', name: 'SC Product Report', type: 'link' }
    ],
  }, // END USED MENU
  // {
  //   state: '',
  //   name: 'Personal',
  //   type: 'saperator',
  //   icon: 'av_timer'
  // },
  // {
  //   state: 'dashboards',
  //   name: 'Dashboards',
  //   type: 'sub',
  //   icon: 'av_timer',
  //   children: [
  //     { state: 'dashboard1', name: 'Dashboard 1', type: 'link' },
  //     { state: 'dashboard2', name: 'Dashboard 2', type: 'link' }
  //   ]
  // },
  // {
  //   state: 'reports',
  //   name: 'Reports',
  //   type: 'sub',
  //   icon: 'insert_chart',
  //   children: [
  //     {
  //       state: 'report',
  //       name: 'Report 1',
  //       type: 'subchild',
  //       subchildren: [
  //         { state: 'report', name: 'report', type: 'link' },
  //         { state: 'prospect', name: 'prospect', type: 'link' },
  //         { state: 'booking', name: 'booking', type: 'link' },
  //         { state: 'delivery', name: 'delivery', type: 'link' }
  //       ]
  //     },
  //     {
  //       state: 'report',
  //       name: 'Report 2',
  //       type: 'subchild',
  //       subchildren: [
  //         { state: 'testDriveRadio', name: 'Test Drive Radio', type: 'link' },
  //         { state: 'testDrive', name: 'Test Drive', type: 'link' }
  //       ]
  //     },
  //     {
  //       state: 'report',
  //       name: 'Report 3',
  //       type: 'subchild',
  //       subchildren: [
  //       //  { state: 'closeRatio', name: 'Close Radio', type: 'link' },
  //         { state: 'closingRatio', name: 'Closing Radio', type: 'link' }
  //       ]
  //     },
  //     {
  //       state: 'motivatorReport',
  //       name: 'Source Of Motivator',
  //       type: 'subchild',
  //       subchildren: [
  //         { state: 'sourceOfMotivator', name: 'Source Of Motivator', type: 'link' },
  //         { state: 'motivatorMTD', name: 'Source Of Motivator MTD', type: 'link' },
  //         { state: 'motivatorPreMonth', name: 'Source Of Motivator Previous Month', type: 'link' },
  //         { state: 'motivatorTypeCars', name: 'Source Of Motivator Type Cars', type: 'link' },
  //         { state: 'motivatorYTD', name: 'Source Of Motivator YTD', type: 'link' }
  //       ]
  //     },
  //     { state: 'scProductivity', name: 'SC Productivity', type: 'link' },
  //     {
  //       state: 'leadSource',
  //       name: 'Lead Source',
  //       type: 'subchild',
  //       subchildren: [
  //         { state: 'leadSourceMTD', name: 'LeadSource MTD', type: 'link' },
  //         { state: 'leadSourcePreMonth', name: 'LeadSource Previous Month', type: 'link' },
  //         { state: 'leadSourceYTD', name: 'LeadSource YTD', type: 'link' },
  //         { state: 'leadSourcTypeCars', name: 'LeadSource Type Carss', type: 'link' },
  //        ]
  //     },
  //   ]
  // },
  // {
  //   state: 'material',
  //   name: 'Material Ui',
  //   type: 'sub',
  //   icon: 'bubble_chart',
  //   badge: [{ type: 'red', value: '17' }],
  //   children: [
  //     { state: 'badge', name: 'Badge', type: 'link' },
  //     { state: 'button', name: 'Buttons', type: 'link' },
  //     { state: 'cards', name: 'Cards', type: 'link' },
  //     { state: 'grid', name: 'Grid List', type: 'link' },
  //     { state: 'lists', name: 'Lists', type: 'link' },
  //     { state: 'menu', name: 'Menu', type: 'link' },
  //     { state: 'tabs', name: 'Tabs', type: 'link' },
  //     { state: 'stepper', name: 'Stepper', type: 'link' },
  //     { state: 'ripples', name: 'Ripples', type: 'link' },
  //     { state: 'expansion', name: 'Expansion Panel', type: 'link' },
  //     { state: 'chips', name: 'Chips', type: 'link' },
  //     { state: 'toolbar', name: 'Toolbar', type: 'link' },
  //     { state: 'progress-snipper', name: 'Progress snipper', type: 'link' },
  //     { state: 'progress', name: 'Progress Bar', type: 'link' },
  //     { state: 'dialog', name: 'Dialog', type: 'link' },
  //     { state: 'tooltip', name: 'Tooltip', type: 'link' },
  //     { state: 'snackbar', name: 'Snackbar', type: 'link' },
  //     { state: 'slider', name: 'Slider', type: 'link' },
  //     { state: 'slide-toggle', name: 'Slide Toggle', type: 'link' }
  //   ]
  // },
  // {
  //   state: 'apps',
  //   name: 'Apps',
  //   type: 'sub',
  //   icon: 'apps',
  //   children: [
  //     { state: 'calendar', name: 'Calendar', type: 'link' },
  //     { state: 'messages', name: 'Mail box', type: 'link' },
  //     { state: 'chat', name: 'Chat', type: 'link' },
  //     { state: 'taskboard', name: 'Taskboard', type: 'link' }
  //   ]
  // },
  // {
  //   state: '',
  //   name: 'Forms, Table & Widgets',
  //   type: 'saperator',
  //   icon: 'av_timer'
  // },
  // {
  //   state: 'forms',
  //   name: 'Forms',
  //   type: 'sub',
  //   icon: 'insert_drive_file',
  //   children: [
  //     { state: 'form-layout', name: 'Form-Layout', type: 'link' },
  //     { state: 'autocomplete', name: 'Autocomplete', type: 'link' },
  //     { state: 'checkbox', name: 'Checkbox', type: 'link' },
  //     { state: 'radiobutton', name: 'Radio Button', type: 'link' },
  //     { state: 'datepicker', name: 'Datepicker', type: 'link' },
  //     { state: 'select', name: 'Select', type: 'link' },
  //     { state: 'formfield', name: 'Form Field', type: 'link' },
  //     { state: 'input', name: 'Inputs', type: 'link' },
  //     { state: 'tree', name: 'Tree', type: 'link' },
  //     { state: 'editor', name: 'Editor', type: 'link' },
  //     { state: 'form-validation', name: 'Form Validation', type: 'link' },
  //     { state: 'file-upload', name: 'File Upload', type: 'link' },
  //     { state: 'wizard', name: 'Wizard', type: 'link' },
  //     { state: 'paginator', name: 'Paginator', type: 'link' },
  //     { state: 'sortheader', name: 'Sort Header', type: 'link' }
  //   ]
  // },
  // {
  //   state: 'tables',
  //   name: 'Tables',
  //   type: 'sub',
  //   icon: 'web',
  //   children: [
  //     { state: 'basictable', name: 'Basic Table', type: 'link' },
  //     { state: 'filterable', name: 'Filterable Table', type: 'link' },
  //     { state: 'pagination', name: 'Pagination Table', type: 'link' },
  //     { state: 'sortable', name: 'Sortable Table', type: 'link' },
  //     { state: 'mix', name: 'Mix Table', type: 'link' },
  //     { state: 'smarttable', name: 'Smart Table', type: 'link' }
  //   ]
  // },
  // {
  //   state: 'datatables',
  //   name: 'Data Tables',
  //   type: 'sub',
  //   icon: 'border_all',
  //   children: [
  //     { state: 'basicdatatable', name: 'Basic Data Table', type: 'link' },
  //     { state: 'filter', name: 'Filterable', type: 'link' },
  //     { state: 'editing', name: 'Editing', type: 'link' },
  //     { state: 'materialtable', name: 'Material Table', type: 'link' }
  //   ]
  // },
  // {
  //   state: 'widgets',
  //   name: 'Widgets',
  //   type: 'link',
  //   icon: 'widgets'
  // },
  // {
  //   state: '',
  //   name: 'Extra Component',
  //   type: 'saperator',
  //   icon: 'av_timer'
  // },
  // {
  //   state: 'authentication',
  //   name: 'Authentication',
  //   type: 'sub',
  //   icon: 'perm_contact_calendar',
  //   children: [
  //     { state: 'login', name: 'Login', type: 'link' },
  //     { state: 'register', name: 'Register', type: 'link' },
  //     { state: 'forgot', name: 'Forgot', type: 'link' },
  //     { state: 'lockscreen', name: 'Lockscreen', type: 'link' },
  //     { state: '404', name: 'Error', type: 'link' }
  //   ]
  // },
  // {
  //   state: 'charts',
  //   name: 'Charts',
  //   type: 'sub',
  //   icon: 'insert_chart',
  //   children: [
  //     { state: 'chartjs', name: 'Chart Js', type: 'link' },
  //     { state: 'chartistjs', name: 'Chartist Js', type: 'link' },
  //     { state: 'ngxchart', name: 'Ngx Charts', type: 'link' }
  //   ]
  // },
  // {
  //   state: 'pages',
  //   name: 'Pages',
  //   type: 'sub',
  //   icon: 'content_copy',
  //   children: [
  //     { state: 'timeline', name: 'Timeline', type: 'link' },
  //     { state: 'invoice', name: 'Invoice', type: 'link' },
  //     { state: 'pricing', name: 'Pricing', type: 'link' },
  //     { state: 'helper', name: 'Helper Classes', type: 'link' },
  //     {
  //       state: 'icons',
  //       name: 'Icons',
  //       type: 'subchild',
  //       subchildren: [
  //         {
  //           state: 'material',
  //           name: 'Material Icons',
  //           type: 'link'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   state: 'multi',
  //   name: 'Menu Levels',
  //   icon: 'star',
  //   type: 'sub',
  //   children: [
  //     {
  //       state: 'second-level',
  //       name: 'Second Level',
  //       type: 'link'
  //     },
  //     {
  //       state: 'third-level',
  //       name: 'Second Level',
  //       type: 'subchild',
  //       subchildren: [
  //         {
  //           state: 'third-level',
  //           name: 'Third Level',
  //           type: 'link'
  //         }
  //       ]
  //     },
  //     {
  //       state: 'third-level',
  //       name: 'aSecond Level',
  //       type: 'subchild',
  //       subchildren: [
  //         {
  //           state: 'athird-level',
  //           name: 'Third Level',
  //           type: 'link'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}

export class MenuAdminItems {
  getMenuitem(): Menu[] {
    return MENUADMINITEMS;
  }
}
